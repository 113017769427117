import { FC } from "react";
import { Box, TextField } from "@mui/material";
const ProductMarketplace: FC = () => {
  return (
    <Box
      sx={{
        mx: "auto",
        color: "#fff",
        p: 1,
        m: 1,
        textAlign: "center",
      }}
    >
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Marketplace"
          variant="outlined"
          sx={{
            mr: 1,
          }}
        />
        <TextField id="outlined-basic" label="SKU" variant="outlined" />
        <TextField id="outlined-basic" label="Price" variant="outlined" />
      </Box>
    </Box>
  );
};
export default ProductMarketplace;
