/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from "react";
import ProductDescriptionForm from "./Form";
import { Grid, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import api from "../../services/api";
type ProductDescriptionProps = {
  isLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

type Description = {
  name: string;
  description: string;
  meta_title: string;
  meta_description: string;
  meta_keyword: string;
  seo_keyword: string;
};

interface AxiosResponse {
  response: Description[];
}

const CategoryDescription: FC<ProductDescriptionProps> = ({ isLoader }) => {
  const [values, setValues] = useState("1");
  const [description, setDescription] = useState<Description[]>([
    {
      name: "",
      description: "",
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      seo_keyword: "",
    },
  ]);
  const fetchData = async (language_id = 2) => {
    isLoader(true);
    const apis = await api.get<AxiosResponse>(
      `/productdescription?product_id=24427&language_id=${language_id}`
    );
    setDescription(apis.data.response);
    isLoader(false);
  };

  const handleChanges = (event: React.SyntheticEvent, newValue: string) => {
    setValues(newValue);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={values}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChanges}
                centered
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Brazil"
                  value="1"
                  onClick={() => {
                    fetchData(2);
                  }}
                />
                <Tab
                  label="English"
                  value="2"
                  onClick={() => {
                    fetchData(1);
                  }}
                />
                <Tab
                  label="Español"
                  value="3"
                  onClick={() => {
                    fetchData(6);
                  }}
                />
                <Tab
                  label="Japanese"
                  value="4"
                  onClick={() => {
                    fetchData(5);
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ProductDescriptionForm description={description} />
            </TabPanel>
            <TabPanel value="2">
              <ProductDescriptionForm description={description} />
            </TabPanel>
            <TabPanel value="3">
              <ProductDescriptionForm description={description} />
            </TabPanel>
            <TabPanel value="4">
              <ProductDescriptionForm description={description} />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};
export default CategoryDescription;
