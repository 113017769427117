import { FC } from "react";
import { Person, Email, Phone } from "@mui/icons-material";
import TableResponsiveVertical from "../TableResponsiveVertical";
import Title from "../Title";

type CustomerDetailsProps = {
  name?: string;
  email?: string;
  phone?: string;
};

const CustomerDetails: FC<CustomerDetailsProps> = ({ name, email, phone }) => {
  return (
    <>
      <Title>Customer Details</Title>
      <TableResponsiveVertical
        data={[
          {
            icon: <Person />,
            data: name,
          },
          {
            icon: <Email />,
            data: email,
          },
          {
            icon: <Phone />,
            data: phone,
          },
        ]}
        objectKey={[["icon", "data"]]}
      />
    </>
  );
};
export default CustomerDetails;
