import { useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import ProductDescription from "../../ProductDescription";
import ProductCodes from "../../ProductCodes";
import ProductPrices from "../../ProductPrices";
import ProductMeasures from "../../ProductMeasures";
import ProductMarketplace from "../../ProductMarketplace";
import ProductStock from "../../ProductStock";
import ProductSupplier from "../../ProductSupplier";
import ProductCompetitors from "../../ProductCompetitors";
import ProductCostCenter from "../../ProductCostCenter";
import ProductImages from "../../ProductImages";
import GlobalLoader from "../../GlobalLoader";
import { Container, Grid, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation } from "react-router-dom";
export default function Product() {
  const [value, setValue] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  return (
    <>
      {isLoading && <GlobalLoader />}
      <DefaultLayout>
        <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Product Description" value="1" />
                      <Tab label="Codes" value="2" disabled />
                      <Tab label="Prices" value="3" />
                      <Tab label="Measures" value="4" disabled />
                      <Tab label="Marketplace" value="5" disabled />
                      <Tab label="Stock" value="6" />
                      <Tab label="Suppliers" value="7" disabled />
                      <Tab label="Competitors" value="8" disabled />
                      <Tab label="Cost Center" value="9" disabled />
                      <Tab label="Images" value="10" disabled />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <ProductDescription
                      isLoader={setIsLoading}
                      product_id={Number(query.get("id"))}
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <ProductCodes />
                  </TabPanel>
                  <TabPanel value="3">
                    <ProductPrices
                      isLoader={setIsLoading}
                      product_id={Number(query.get("id"))}
                    />
                  </TabPanel>
                  <TabPanel value="4">
                    <ProductMeasures />
                  </TabPanel>
                  <TabPanel value="5">
                    <ProductMarketplace />
                  </TabPanel>
                  <TabPanel value="6">
                    <ProductStock
                      isLoader={setIsLoading}
                      product_id={Number(query.get("id"))}
                    />
                  </TabPanel>
                  <TabPanel value="7">
                    <ProductSupplier />
                  </TabPanel>
                  <TabPanel value="8">
                    <ProductCompetitors />
                  </TabPanel>
                  <TabPanel value="9">
                    <ProductCostCenter />
                  </TabPanel>
                  <TabPanel value="10">
                    <ProductImages />
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </DefaultLayout>
    </>
  );
}
