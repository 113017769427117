import { createAction, createReducer } from "@reduxjs/toolkit";
import { LoginAction, LogoutAction } from "../../../types/actions";
const INITIAL_STATE = {
  isAuthenticated: localStorage.getItem("token") ? true : false,
  role: localStorage.getItem("role") ? localStorage.getItem("role") : 0,
};

export const login = createAction<LoginAction>("LOGIN");
export const logout = createAction<LogoutAction>("LOGOUT");

export default createReducer(INITIAL_STATE, {
  [login.type]: (state, action: LoginAction) => ({
    ...state,
    isAuthenticated: action.payload.isAuthenticated,
    role: action.payload.role,
  }),
  [logout.type]: (state) => ({ ...state, isAuthenticated: false, role: 0 }),
});
