import api from "../../services/api";
import { login } from "../ducks/auth";
import { addOrders } from "../ducks/orders";
import { addOrder } from "../ducks/order";
import { UserLogin } from "../../types/user";
import Swal from "sweetalert2";
import { Dispatch } from "redux";

interface Token {
  response: { token: string; result?: []; role_id: number };
}

type OrdersType = {
  id: number;
  first_name: string;
  last_name: string;
  customer_id: number;
  status: string;
  created_at: string;
  updated_at: string;
  total: number;
};

interface Orders {
  response: OrdersType[];
}

export const loginRequest = (user: UserLogin) => {
  return (dispatch: Dispatch) => {
    api.get("/csrf-cookie").then((response) => {
      api
        .post<Token>("auth/login", user)
        .then((res) => {
          if (res.data.response.token) {
            localStorage.setItem("token", res.data.response.token);
            localStorage.setItem("role", String(res.data.response.role_id));
            dispatch(
              login({
                type: "LOGIN",
                payload: {
                  isAuthenticated: true,
                  role: res.data.response.role_id,
                },
              })
            );
            return (window.location.href = "/");
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: "Do you want to continue",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    });
  };
};

export const getAllOrders = (store: number) => {
  return (dispatch: Dispatch) => {
    api.get<Orders>(`/orders?store=${store}`).then((res) => {
      dispatch(
        addOrders({
          type: "ADD_ALL_ORDERS",
          payload: res.data.response,
        })
      );
    });
  };
};

export const getOrder = (order: string, store: number) => {
  return (dispatch: Dispatch) => {
    api
      .get<Orders>(`/order?order_id=${order}&store_id=${store}`)
      .then((res) => {
        dispatch(
          addOrder({
            type: "ADD_ORDER",
            payload: res.data.response,
          })
        );
      });
  };
};
