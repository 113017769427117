import Orders from "../../components/views/Orders";
import Order from "../../components/views/Order";
import Customers from "../../components/views/Customers";
import Products from "../../components/views/Products";
import Product from "../../components/views/Product";
import Categories from "../../components/views/Categories";
import Attributes from "../../components/views/Attributes";
import AttributeGroups from "../../components/views/AttributeGroups";
export const DASHBOARD_PATH = "/";
export const LOGIN_PATH = "/login";
const ORDERS_PATH = "/orders";
const ORDER_PATH = "/order";
const CUSTOMERS_PATH = "/customers";
const PRODUCTS_PATH = "/products";
const PRODUCT_PATH = "/product";
const CATEGORIES_PATH = "/categories";
const ATTRIBUTES_PATH = "/attributes";
const ATTRIBUTESGROUPS_PATH = "/attributegroups";
export const paths = [
  {
    path: ORDER_PATH,
    component: Order,
    role_id: 0,
  },
  {
    path: ORDERS_PATH,
    component: Orders,
    role_id: 0,
  },
  {
    path: CUSTOMERS_PATH,
    component: Customers,
    role_id: 0,
  },
  {
    path: PRODUCTS_PATH,
    component: Products,
    role_id: 0,
  },
  {
    path: PRODUCT_PATH,
    component: Product,
    role_id: 0,
  },
  {
    path: CATEGORIES_PATH,
    component: Categories,
    role_id: 0,
  },
  {
    path: ATTRIBUTES_PATH,
    component: Attributes,
    role_id: 0,
  },
  {
    path: ATTRIBUTESGROUPS_PATH,
    component: AttributeGroups,
    role_id: 0,
  },
];
