import DefaultLayout from "../../layouts/DefaultLayout";
import { Container, Grid, Paper } from "@mui/material";
import TaskList from "../../TaskListPreview";
import CustumerQuestionsList from "../../CustumerQuestionsListPreview";
import OrderListPreview from "../../OrderListPreview";
import "./style.css";
export default function Dashboard() {
  return (
    <DefaultLayout>
      <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={6}>
            <Paper className="card__dashboard">
              <TaskList />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <Paper className="card__dashboard">
              <CustumerQuestionsList />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <Paper className="card__dashboard">
              <OrderListPreview
                title={"Pedidos Loja"}
                items={[
                  {
                    store: "SOS Máscaras",
                    date: "1999/05/11",
                    id: 1,
                    customer: "ives",
                    status: "concluido",
                    price: 20.0,
                  },
                  {
                    store: "Y&Bugs",
                    date: "1999/05/11",
                    id: 1,
                    customer: "ddd",
                    status: "concluido",
                    price: 20.0,
                  },
                ]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <Paper className="card__dashboard">
              <OrderListPreview
                title={"Pedidos marketplaces"}
                items={[
                  {
                    store: "Mercado Livre",
                    date: "1999/05/11",
                    id: 1,
                    customer: "ives",
                    status: "concluido",
                    price: 20.0,
                  },
                  {
                    store: "Ebay",
                    date: "1999/05/11",
                    id: 1,
                    customer: "ddd",
                    status: "concluido",
                    price: 20.0,
                  },
                ]}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
