import { FC } from "react";
import TableResponsive from "../TableResponsive";
import TableResponsiveVertical from "../TableResponsiveVertical";
import Title from "../Title";

type Product = {
  name: string;
  quantity: number;
  sku: string;
  price: string;
  total: string;
};

type OrderSummaryProductsProps = {
  products: Product[];
  subtotal: string;
  shipping: string;
  shipping_total: string;
  total: string;
};

const colulas = ["Product", "sku", "Quantity", "Price", "Total"];
const keys = ["name", "sku", "quantity", "price", "total"];
const OrderSummaryProducts: FC<OrderSummaryProductsProps> = ({
  products,
  subtotal,
  shipping,
  shipping_total,
  total,
}) => {
  return (
    <>
      <Title>Products</Title>
      <TableResponsive columns={colulas} data={products} objectKey={keys} />
      <TableResponsiveVertical
        textAlign={"text-right"}
        data={[
          {
            text: "Sub-total",
            value: subtotal,
          },
          {
            text: shipping,
            value: shipping_total,
          },
          {
            text: "Total",
            value: total,
          },
        ]}
        objectKey={[["text", "value"]]}
      />
    </>
  );
};
export default OrderSummaryProducts;
