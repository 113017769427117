import { FC } from "react";
import TableResponsiveVertical from "../TableResponsiveVertical";
import Title from "../Title";
import {
  ShoppingCart,
  DateRange,
  CreditCard,
  LocalShipping,
} from "@mui/icons-material";
import moment from "moment";
type OrderDetailsProps = {
  store?: string;
  date?: string;
  shipping?: string;
};

const OrderDetails: FC<OrderDetailsProps> = ({ store, date, shipping }) => {
  return (
    <>
      <Title>Order Details</Title>
      <TableResponsiveVertical
        data={[
          {
            icon: <ShoppingCart />,
            data: store,
          },
          {
            icon: <DateRange />,
            data: moment(date).format("L"),
          },
          {
            icon: <CreditCard />,
            data: "Mercado Pago",
          },
          {
            icon: <LocalShipping />,
            data: shipping,
          },
        ]}
        objectKey={[["icon", "data"]]}
      />
    </>
  );
};
export default OrderDetails;
