import { FC } from "react";
import { Box, TextField } from "@mui/material";
const ProductCodes: FC = () => {
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TextField fullWidth label="SKU" id="fullWidth" margin="normal" />
      <TextField fullWidth label="NCM" id="fullWidth" margin="normal" />
      <TextField fullWidth label="EAN" id="fullWidth" margin="normal" />
      <TextField fullWidth label="JAN" id="fullWidth" margin="normal" />
      <TextField fullWidth label="UPC" id="fullWidth" margin="normal" />
      <TextField fullWidth label="ISBN" id="fullWidth" margin="normal" />
      <TextField fullWidth label="MPN" id="fullWidth" margin="normal" />
    </Box>
  );
};
export default ProductCodes;
