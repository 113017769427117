import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { TableProps } from "../../types/tableResponsive";
import { makeStyles } from "@mui/styles";
import { Visibility } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  eye: {
    backgroundColor: "transparent",
    position: "relative",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
}));

const handleToPage = (id: any, screen: string | undefined, store = 13) => {
  window.location.href = `/${screen}?store=${store}&id=${id}`;
};
const parseLines = (value: string) => value.replace(/(\\n)/g, "\n");
const TableResponsive = (props: TableProps) => {
  const classes = useStyles();
  return (
    <Paper className="container">
      <Table>
        <TableHead>
          <TableRow>
            {props.columns.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((column, index) => {
            return (
              <TableRow key={index}>
                {props.objectKey.map((key) => {
                  return (
                    <TableCell key={key}>
                      {key === "action" ? (
                        <IconButton
                          color="inherit"
                          className={classes.eye}
                          onClick={() => {
                            handleToPage(column.id, props.screen);
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      ) : (
                        <>
                          {key === "price" ? (
                            parseLines(column[key])
                          ) : (
                            <>
                              {key === "image" ? (
                                <CardMedia
                                  component="img"
                                  sx={{ width: 151 }}
                                  image={`https://sosmascaras.s3.us-east-2.amazonaws.com/${column[key]}`}
                                  alt="Live from space album cover"
                                />
                              ) : (
                                column[key]
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};
export default TableResponsive;
