import { FC, useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/HighlightOff";
import api from "../../services/api";
import SendIcon from "@mui/icons-material/Send";
import Swal from "sweetalert2";

type FildType = {
  warehouse_id: string | String;
  quantity: string | String;
  minimum_quantity: string | String;
  location: string | String;
  product_id: number;
};

type Warehouses = {
  id: string;
  name: string | String;
};

interface AxiosResponseWarehouses {
  response: FildType[];
}
interface AxiosResponse {
  response: Warehouses[];
}

type ProductStockProps = {
  isLoader: React.Dispatch<React.SetStateAction<boolean>>;
  product_id: number;
};

const ProductStock: FC<ProductStockProps> = ({ isLoader, product_id }) => {
  const [fields, setFields] = useState<FildType[]>([
    {
      warehouse_id: "",
      quantity: "",
      minimum_quantity: "",
      location: "",
      product_id: product_id,
    },
  ]);

  const [warehouses, setWarehouses] = useState<Warehouses[]>([
    {
      id: "0",
      name: "",
    },
  ]);

  const fetchDataWarehouses = async () => {
    isLoader(true);
    const apis = await api.get<AxiosResponse>(`/warehouses`);
    setWarehouses(apis.data.response);
    isLoader(false);
  };

  const fetchGetWarehouses = async () => {
    const apis = await api.get<AxiosResponseWarehouses>(
      `/product/${product_id}/warehouse`
    );
    setFields(apis.data.response);
    isLoader(false);
  };

  const fetchSaveWarehouses = async () => {
    isLoader(true);
    api
      .post(`/product/warehouse`, fields)
      .then((res) => {
        isLoader(false);
        Swal.fire({
          title: "Success",
          text: "Product warehouse save",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((err) => {
        isLoader(false);
        Swal.fire({
          title: "Error",
          text: "Error save product warehouse",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const deleteWarehouse = async (
    product_id: number,
    warehouse_id: string | String
  ) => {
    isLoader(true);
    api
      .delete(`product/warehouse/delete`, {
        data: {
          product_id,
          warehouse_id: Number(warehouse_id),
        },
      })
      .then((res) => {
        isLoader(false);
        Swal.fire({
          title: "Success",
          text: "Product warehouse deleted",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((err) => {
        isLoader(false);
        Swal.fire({
          title: "Error",
          text: "Error deleted product warehouse",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    fetchDataWarehouses();
    fetchGetWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleChangeInput(
    i: number,
    event: SelectChangeEvent<String> | ChangeEvent<HTMLInputElement>,
    name: string
  ) {
    const values = [...fields];
    const { value } = event.target;
    if (name === "warehouse") {
      values[i].warehouse_id = value;
    }
    if (name === "quantity") {
      values[i].quantity = value;
    }
    if (name === "min_quantity") {
      values[i].minimum_quantity = value;
    }
    if (name === "location") {
      values[i].location = value;
    }
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({
      warehouse_id: "",
      quantity: "",
      minimum_quantity: "",
      location: "",
      product_id: product_id,
    });
    setFields(values);
  }

  function handleRemove(i: number) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  return (
    <Box
      sx={{
        mx: "auto",
        color: "#000",
        m: 1,
      }}
    >
      <Button
        variant="contained"
        onClick={() => handleAdd()}
        endIcon={<AddIcon />}
      >
        ADD
      </Button>
      <Divider sx={{ m: 1 }} />
      {fields.map((field, idx) => {
        return (
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "20ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Select
              labelId="warehouse"
              id="warehouse"
              label="Warehouse"
              value={field.warehouse_id}
              onChange={(e: SelectChangeEvent<String>) =>
                handleChangeInput(idx, e, "warehouse")
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {warehouses.map((warehouse) => {
                return (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </MenuItem>
                );
              })}
            </Select>
            <TextField
              id="outlined-basic"
              label="Quantity"
              value={field.quantity}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(idx, e, "quantity")
              }
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              label="Minimum Quantity"
              value={field.minimum_quantity}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(idx, e, "min_quantity")
              }
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              label="Location"
              value={field.location}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(idx, e, "location")
              }
              variant="outlined"
            />
            <Button
              variant="contained"
              onClick={() => {
                handleRemove(idx);
                deleteWarehouse(product_id, field.warehouse_id);
              }}
              endIcon={<RemoveIcon />}
            >
              Remove
            </Button>
          </Box>
        );
      })}
      <Divider sx={{ m: 1 }} />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Button
          variant="contained"
          onClick={() => {
            fetchSaveWarehouses();
          }}
          endIcon={<SendIcon />}
        >
          Send
        </Button>
      </Stack>
    </Box>
  );
};
export default ProductStock;
