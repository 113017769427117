import { FC } from "react";
import TableResponsive from "../TableResponsive";
import Title from "../Title";
import { OrderListProps } from "../../types/orderList";
const OrderList: FC<OrderListProps> = ({ title, columns, data, objectKey }) => {
  return (
    <>
      <Title>{title}</Title>
      <TableResponsive
        columns={columns}
        data={data}
        objectKey={objectKey}
        screen={"order"}
      />
    </>
  );
};
export default OrderList;
