import { FC, useState, useEffect } from "react";
import { IconButton, Input, Select, MenuItem } from "@mui/material";
import { Save } from "@mui/icons-material";
import TableResponsiveVertical from "../TableResponsiveVertical";
import Title from "../Title";
import api from "../../services/api";
import Swal from "sweetalert2";
type TrackingDetailsProps = {
  trackingCode: string;
  trackingCompany: string;
  order_id: string;
  isLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

const TrackingDetails: FC<TrackingDetailsProps> = ({
  trackingCode,
  trackingCompany,
  order_id,
  isLoader,
}) => {
  const [method, setMethod] = useState(String(trackingCompany));
  const [code, setCode] = useState(trackingCode);

  const handleChangeValue = (event: any) => {
    setCode(event.target.value);
  };

  useEffect(() => {
    setMethod(String(trackingCompany));
    setCode(trackingCode);
  }, [trackingCode, trackingCompany]);

  const handleSave = async () => {
    isLoader(true);
    await api
      .put("/order/trackingcode", {
        tracking_code: code,
        id: order_id,
      })
      .then(() => {
        isLoader(false);
        Swal.fire({
          title: "Success",
          text: "Order tracking code updated",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch(() => {
        isLoader(false);
        Swal.fire({
          title: "Error",
          text: "Error updating order tracking code",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <>
      <Title>Tracking Details</Title>
      <TableResponsiveVertical
        data={[
          {
            text: "Tracking Code",
            input: (
              <Input
                sx={{
                  width: "100%",
                }}
                onChange={handleChangeValue}
                value={code}
                placeholder={code ?? "Tracking Code"}
              />
            ),
            button: (
              <IconButton
                onClick={() => {
                  handleSave();
                }}
              >
                <Save />
              </IconButton>
            ),
          },
          {
            text: "Tracking Link",
            input: (
              <Input
                readOnly
                sx={{
                  width: "100%",
                }}
                value={
                  // eslint-disable-next-line eqeqeq
                  trackingCompany == "3"
                    ? `https://www.melhorrastreio.com.br/meu-rastreio/${code}`
                    : ""
                }
                placeholder={"Tracking Link"}
              />
            ),
            button: "",
          },
          {
            text: "Tracking Method",
            input: (
              <Select
                displayEmpty
                onChange={(e) => setMethod(e.target.value)}
                value={method}
                sx={{
                  width: "100%",
                }}
              >
                <MenuItem value={""} disabled>
                  Select method
                </MenuItem>
                <MenuItem value={"1"}>Fedex</MenuItem>
                <MenuItem value={"2"}>USPS</MenuItem>
                <MenuItem value={"3"}>Correios</MenuItem>
              </Select>
            ),
            button: "",
          },
        ]}
        objectKey={[["text", "input", "button"]]}
      />
    </>
  );
};
export default TrackingDetails;
