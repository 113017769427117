import DefaultLayout from "../../layouts/DefaultLayout";
import { Container, Grid, Paper } from "@mui/material";
import OrderDetails from "../../OrderDetails";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomerDetails from "../../CustomerDetails";
import OrderActions from "../../OrderActions";
import TrackingDetails from "../../TrackingDetails";
import OrderAddressSummary from "../../OrderAddressSummary";
import OrderSummaryProducts from "../../OrderSummaryProducts";
import OrderHistory from "../../OrderHistory";
import { getOrder } from "../../../store/fetchActions";
import { RootState } from "../../../store";
import { useLocation } from "react-router-dom";
import GlobalLoader from "../../GlobalLoader";
export default function Order() {
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  const dispatch = useDispatch();
  const order = useSelector((state: RootState) => state.order);
  useEffect(() => {
    dispatch(getOrder(String(query.get("id")), Number(query.get("store"))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  if (Object.keys(order).length === 0) return <GlobalLoader />;
  return (
    <>
      {isLoading && <GlobalLoader />}
      <DefaultLayout>
        <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className="card__dashboard">
                <OrderDetails
                  store={order.order.store}
                  date={order.order.date}
                  shipping={order.order.shipping_method}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className="card__dashboard">
                <CustomerDetails
                  name={order.customer.name}
                  email={order.customer.email}
                  phone={order.customer.phone}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className="card__dashboard">
                <OrderActions
                  order_id={query.get("id")}
                  isLoader={setIsLoading}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <Paper className="card__dashboard">
                <OrderAddressSummary
                  title="Shipping Address"
                  address={order.shipping_address.address}
                  address2={order.shipping_address.address2}
                  city={order.shipping_address.city}
                  zone={order.shipping_address.zone}
                  country={order.shipping_address.country}
                  postal_code={order.shipping_address.postal_code}
                  number={order.shipping_address.number}
                  name={order.shipping_address.name}
                  district={order.shipping_address.district}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper className="card__dashboard">
                <OrderAddressSummary 
                  title="Payment  Address"
                  address={order.payment_address.address}
                  address2={order.shipping_address.address2}
                  city={order.payment_address.city}
                  zone={order.payment_address.zone}
                  country={order.payment_address.country}
                  postal_code={order.payment_address.postal_code}
                  number={order.payment_address.number}
                  name={order.payment_address.name}
                  district={order.payment_address.district}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className="card__dashboard">
                <TrackingDetails
                  trackingCode={order.order.tracking_code}
                  trackingCompany={order.order.shipping_company_id}
                  order_id={String(query.get("id"))}
                  isLoader={setIsLoading}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className="card__dashboard">
                <OrderSummaryProducts
                  products={order.products}
                  subtotal={order.order.sub_total}
                  shipping={order.order.shipping_method}
                  shipping_total={order.order.shipping_price}
                  total={order.order.total}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className="card__dashboard">
                <OrderHistory
                  order_id={query.get("id")}
                  store_id={query.get("store")}
                  isLoader={setIsLoading}
                  history={order.history}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </DefaultLayout>
    </>
  );
}
