import { FC, useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Stack,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/HighlightOff";
import api from "../../services/api";
import Swal from "sweetalert2";

type FildType = {
  store_id: string | String;
  price: string | String;
  price_sale: string | String;
  product_id: number;
  status: number;
  is_sale: number;
};

type Stores = {
  id: string;
  name: string | String;
};

interface AxiosResponseStores {
  response: Stores[];
}

interface AxiosResponsePrices {
  response: FildType[];
}

type ProductPricesProps = {
  isLoader: React.Dispatch<React.SetStateAction<boolean>>;
  product_id: number;
};

const ProductPrices: FC<ProductPricesProps> = ({ isLoader, product_id }) => {
  const [fields, setFields] = useState<FildType[]>([
    {
      store_id: "",
      price: "",
      price_sale: "",
      status: 0,
      is_sale: 0,
      product_id,
    },
  ]);

  const [stores, setStores] = useState<Stores[]>([
    {
      id: "0",
      name: "",
    },
  ]);

  const fetchDataStores = async () => {
    isLoader(true);
    const apis = await api.get<AxiosResponseStores>(`/stores`);
    setStores(apis.data.response);
  };

  const fetchData = async () => {
    isLoader(true);
    const apis = await api.get<AxiosResponsePrices>(
      `/product/${product_id}/price`
    );
    setFields(apis.data.response);
    isLoader(false);
  };

  const fetchSave = async () => {
    isLoader(true);
    api
      .post(`/product/price`, fields)
      .then((res) => {
        isLoader(false);
        console.log(res);
        Swal.fire({
          title: "Success",
          text: "Product price updated",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((err) => {
        isLoader(false);
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Error updating product price",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const deletePrice = async (product_id: number, store_id: string | String) => {
    isLoader(true);
    api
      .delete(`product/price/delete`, {
        data: {
          product_id,
          store_id: Number(store_id),
        },
      })
      .then((res) => {
        isLoader(false);
        console.log(res);
        Swal.fire({
          title: "Success",
          text: "Product price deleted",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((err) => {
        isLoader(false);
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Error deleted product price",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    fetchDataStores();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeStore(
    i: number,
    event: SelectChangeEvent<String>,
    name: string
  ) {
    const values = [...fields];
    const { value } = event.target;
    values[i].store_id = value;
    setFields(values);
  }

  function handleChangePrice(
    i: number,
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) {
    const values = [...fields];
    const { value } = event.target;
    values[i].price = value;
    setFields(values);
  }

  function handleChangePricePromo(
    i: number,
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) {
    const values = [...fields];
    const { value } = event.target;
    values[i].price_sale = value;
    setFields(values);
  }

  function handleChangeChecks(
    i: number,
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) {
    const values = [...fields];
    if (name === "status") {
      if (event.target.checked) {
        values[i].status = 1;
      } else {
        values[i].status = 0;
      }
    }
    if (name === "is_sale") {
      if (event.target.checked) {
        values[i].is_sale = 1;
      } else {
        values[i].is_sale = 0;
      }
    }
    setFields(values);
  }
  function handleAdd() {
    const values = [...fields];
    values.push({
      store_id: "",
      price: "",
      price_sale: "",
      status: 0,
      is_sale: 0,
      product_id,
    });
    setFields(values);
  }

  function handleRemove(i: number) {
    const values = [...fields];
    console.log(i);
    values.splice(i, 1);
    setFields(values);
  }
  return (
    <Box
      sx={{
        mx: "auto",
        color: "#000",
        m: 1,
      }}
    >
      <Button
        variant="contained"
        onClick={() => handleAdd()}
        endIcon={<AddIcon />}
      >
        ADD
      </Button>
      <Divider
        sx={{
          m: 1,
        }}
      />
      {fields.map((field, idx) => {
        return (
          <Box
            key={`${field}-${idx}`}
            sx={{
              "& > :not(style)": { m: 1, width: "20ch" },
            }}
          >
            <Select
              labelId="store"
              id="store"
              label="Store"
              value={field.store_id}
              onChange={(e: SelectChangeEvent<String>) =>
                handleChangeStore(idx, e, "store")
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {stores.map((store) => {
                return (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                );
              })}
            </Select>
            <TextField
              id="outlined-basic"
              label="Price"
              value={field.price}
              variant="outlined"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangePrice(idx, e, "price")
              }
            />
            <TextField
              id="outlined-basic"
              label="Price Promo"
              value={field.price_sale}
              variant="outlined"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangePricePromo(idx, e, "pricepromo")
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.is_sale ? true : false}
                  name="is_sale"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeChecks(idx, e, "is_sale")
                  }
                />
              }
              label="is Promo?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.status ? true : false}
                  value="1"
                  name="status"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeChecks(idx, e, "status")
                  }
                />
              }
              label="status Product?"
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleRemove(idx);
                deletePrice(product_id, field.store_id);
              }}
              endIcon={<RemoveIcon />}
            >
              Remove
            </Button>
          </Box>
        );
      })}
      <Divider
        sx={{
          m: 1,
        }}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Button
          variant="contained"
          onClick={() => fetchSave()}
          endIcon={<SendIcon />}
        >
          Send
        </Button>
      </Stack>
    </Box>
  );
};
export default ProductPrices;
