import { FC } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  TextField,
  Typography,
  CardMedia,
  Select,
  MenuItem,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/HighlightOff";
const CategoryData: FC = () => {
  return (
    <>
      <Paper sx={{ p: 1 }}>
        <TextField label="Parent" fullWidth />
      </Paper>
      <Paper sx={{ p: 1, mt: 1 }}>
        <Typography variant="body2">Stores</Typography>
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            p: 1,
          }}
        >
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Label"
            />
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Label"
            />
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Label"
            />
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Label"
            />
          </FormGroup>
        </Box>
      </Paper>
      <Paper sx={{ p: 1, mt: 1 }}>
        <TextField label="Filters" fullWidth />
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            height: 150,
            p: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <RemoveIcon />
            <span>
              Beauty and Personal Care {">"} Personal Hygiene {">"} Mask {">"}{" "}
              KN95
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <RemoveIcon />
            Beauty and Personal Care {">"} Personal Hygiene {">"} Mask {">"}{" "}
            KN95
          </div>
        </Box>
      </Paper>
      <Paper sx={{ p: 1, mt: 1 }}>
        <Typography variant="body2">Image</Typography>
        <Box
          sx={{
            p: 1,
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image="https://via.placeholder.com/150"
            alt="Live from space album cover"
          />
        </Box>
      </Paper>
      <Paper sx={{ p: 1, mt: 1 }}>
        <Select id="status" label="status" value="" fullWidth>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="1">
            <em>Enabled</em>
          </MenuItem>
          <MenuItem value="0">
            <em>Disabled</em>
          </MenuItem>
        </Select>
      </Paper>
    </>
  );
};
export default CategoryData;
