/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Container, Grid, Paper, Box, Pagination } from "@mui/material";
import ProductList from "../../ProductList";
import api from "../../../services/api";
import { IProducts, AxiosResponse } from "../../../types/products";
import GlobalLoader from "../../GlobalLoader";

export default function Products() {
  const [loading, setLoading] = useState(true);

  const [products, serProducts] = useState<IProducts>({
    current_page: 0,
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    next_page_url: null,
    path: "",
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0,
  });

  const [page, setPage] = useState(1);

  const fetchData = async (pageNumber = 1) => {
    setLoading(true);
    const apis = await api.get<AxiosResponse>(
      `/products?store=13&language_id=2&page=${pageNumber}`
    );
    setLoading(false);
    serProducts(apis.data.response);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    fetchData(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cols = [
    "Image",
    "Product Name",
    "SKU",
    "Manufacturer",
    "Prices",
    "Quantity",
    "Status",
  ];
  const objectKeys = [
    "image",
    "product_name",
    "sku",
    "manufacturer_name",
    "price",
    "quantity",
    "action",
  ];
  return (
    <>
      {loading && <GlobalLoader />}
      <DefaultLayout>
        <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className="card__orders">
                <ProductList
                  title={"SOS Máscaras"}
                  columns={cols}
                  data={products.data}
                  objectKey={objectKeys}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box
                display="flex"
                width={"100%"}
                height={80}
                alignItems="center"
                justifyContent="center"
              >
                <Pagination
                  count={products.last_page}
                  page={page}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </DefaultLayout>
    </>
  );
}
