/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Container, Grid, Paper, Box, Pagination } from "@mui/material";
import OrdersList from "../../OrderList";
import api from "../../../services/api";
import { IOrders, AxiosResponse } from "../../../types/orders";
import GlobalLoader from "../../GlobalLoader";

export default function Orders() {
  const [loading, setLoading] = useState(true);

  const [orders, serOrders] = useState<IOrders>({
    current_page: 0,
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    next_page_url: null,
    path: "",
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0,
  });

  const [page, setPage] = useState(1);

  const fetchData = async (pageNumber = 1) => {
    const apis = await api.get<AxiosResponse>(
      `/orders?store=13&page=${pageNumber}`
    );
    setLoading(false);
    serOrders(apis.data.response);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    fetchData(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cols = ["Order ID", "Customer", "Status", "Total", "Date", "Action"];
  const objectKeys = [
    "friendly_id",
    "first_name",
    "status",
    "total",
    "created_at",
    "action",
  ];
  return (
    <>
      {loading && <GlobalLoader />}
      <DefaultLayout>
        <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className="card__orders">
                <OrdersList
                  title={"SOS Máscaras"}
                  columns={cols}
                  data={orders.data}
                  objectKey={objectKeys}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box
                display="flex"
                width={"100%"}
                height={80}
                alignItems="center"
                justifyContent="center"
              >
                <Pagination
                  count={orders.last_page}
                  page={page}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </DefaultLayout>
    </>
  );
}
