import { ReactNode, FC } from "react";
import { Typography } from "@mui/material";

interface TitleProps {
  childrean?: ReactNode;
}

const Title: FC<TitleProps> = ({ children }) => {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {children}
    </Typography>
  );
};

export default Title;
