import { createAction, createReducer } from "@reduxjs/toolkit";
import { AddOrdersAction, addOrderAction } from "../../../types/actions";
const INITIAL_STATE: any[] = [];

export const addOrders = createAction<AddOrdersAction>("ADD_ALL_ORDERS");
export const addOrder = createAction<addOrderAction>("ADD_ORDER");

export default createReducer(INITIAL_STATE, {
  [addOrders.type]: (state, action) => action.payload.payload,
  [addOrder.type]: (state, action) => action.payload.payload,
});
