/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC } from "react";
import styled from "styled-components";

const GlobalLoader: FC = () => {
  return (
    <LoaderContainer>
      <LoaderImg src={"/assets/image/loader.svg"} />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  opacity: 0.6;
  background: #8d8d8d;
  z-index: 100;
`;
const LoaderImg = styled.img`
  position: absolute;
`;

export default GlobalLoader;
