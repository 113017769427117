import { FC } from "react";
import { Box, TextField } from "@mui/material";
const ProductMeasures: FC = () => {
  return (
    <Box
      sx={{
        mx: "auto",
        color: "#fff",
        p: 1,
        m: 1,
        textAlign: "center",
      }}
    >
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Length"
          variant="outlined"
          sx={{
            mr: 1,
          }}
        />
        <TextField id="outlined-basic" label="Width" variant="outlined" />
        <TextField id="outlined-basic" label="Height" variant="outlined" />
        <TextField
          id="outlined-basic"
          label="Length Class"
          variant="outlined"
        />
      </Box>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField id="outlined-basic" label="Weight" variant="outlined" />
        <TextField
          id="outlined-basic"
          label="Weight Class"
          variant="outlined"
        />
      </Box>
    </Box>
  );
};
export default ProductMeasures;
