import { FC } from "react";
import TableResponsiveVertical from "../TableResponsiveVertical";
import Title from "../Title";
import { OrderAddressSummaryProps } from "../../types/orderSummary";
const OrderAddressSummary: FC<OrderAddressSummaryProps> = ({
  title,
  name,
  address,
  address2,
  city,
  country,
  number,
  postal_code,
  district,
  zone,
}) => {
  return (
    <>
      <Title>{title}</Title>
      <TableResponsiveVertical
        data={[
          {
            text: name,
          },
          {
            text: `${address} , ${number}`,
          },
          {
            text: address2,
          },
          {
            text: district,
          },
          {
            text: `${city} ${postal_code}`,
          },
          {
            text: zone,
          },
          {
            text: country,
          },
        ]}
        objectKey={[["text"]]}
      />
    </>
  );
};
export default OrderAddressSummary;
