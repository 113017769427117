import { FC, useState, useEffect } from "react";
import { Box, TextField, Stack, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import api from "../../services/api";
import Swal from "sweetalert2";
type Description = {
  name: string;
  description: string;
  meta_title: string;
  meta_description: string;
  meta_keyword: string;
  seo_keyword: string;
  language_id: number;
};

type Props = {
  description: Description[];
  language_id: number;
  isLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProductDescriptionForm: FC<Props> = ({
  description,
  language_id,
  isLoader,
}) => {
  const [descriptionState, setDescriptionState] = useState<Description>({
    name: "",
    description: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    seo_keyword: "",
    language_id: language_id,
  });
  useEffect(() => {
    setDescriptionState(description[0]);
  }, [description]);
  const parseLines = (value: string) => value.replace(/(\\n)/g, "\n");
  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDescriptionState({ ...descriptionState, [name]: value });
  };
  const sendDescription = () => {
    isLoader(true);
    api
      .post("/product/description", descriptionState)
      .then((res) => {
        isLoader(false);
        Swal.fire({
          title: "Success",
          text: "Product description updated",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        isLoader(false);
        Swal.fire({
          title: "Error",
          text: "Error updating product description",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TextField
        fullWidth
        label="Product Name"
        value={descriptionState.name}
        id="name"
        name="name"
        margin="normal"
        onChange={changeInput}
      />
      <TextField
        label="Description"
        value={parseLines(descriptionState.description)}
        id="description"
        name="description"
        multiline
        fullWidth
        rows={7}
        onChange={changeInput}
      />
      <TextField
        fullWidth
        value={descriptionState.meta_title}
        label="Meta Tag Title"
        id="fullWidth"
        margin="normal"
        disabled
      />
      <TextField
        label="Meta Tag Short Description"
        value={descriptionState.meta_description}
        id="meta_description"
        name="meta_description"
        multiline
        fullWidth
        rows={4}
        onChange={changeInput}
      />
      <TextField
        fullWidth
        label="Meta Tag Keywords"
        value={descriptionState.meta_keyword}
        id="meta_keyword"
        name="meta_keyword"
        margin="normal"
        onChange={changeInput}
      />
      <TextField
        fullWidth
        label="Product Tags"
        value={descriptionState.seo_keyword}
        id="fullWidth"
        margin="normal"
        disabled
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
      >
        <Button
          variant="contained"
          onClick={() => sendDescription()}
          endIcon={<SendIcon />}
        >
          Send
        </Button>
      </Stack>
    </Box>
  );
};
export default ProductDescriptionForm;
