import { createAction, createReducer } from "@reduxjs/toolkit";
import { addOrderAction } from "../../../types/actions";

type Customer = {
  email: string;
  name: string;
  phone: string;
};

type Order = {
  store: string;
  date: string;
  payment_method: string;
  shipping_method: string;
  shipping_price: string;
  sub_total: string;
  total: string;
  shipping_company_id: string;
  tracking_code: string;
};

type Address = {
  address: string;
  address2: string;
  city: string;
  zone: string;
  country: string;
  district: string;
  name: string;
  number: string;
  postal_code: string;
};

type Product = {
  name: string;
  quantity: number;
  sku: string;
  price: string;
  total: string;
};

type History = {
  date: string;
  comment: string;
  status: string;
  customer_notified: string;
};

const INITIAL_STATE = {
  customer: {} as Customer,
  order: {} as Order,
  payment_address: {} as Address,
  shipping_address: {} as Address,
  products: [] as Array<Product>,
  history: [] as Array<History>,
};

export const addOrder = createAction<addOrderAction>("ADD_ORDER");

export default createReducer(INITIAL_STATE, {
  [addOrder.type]: (state, action) => action.payload.payload,
});
