import { FC } from "react";
import TableResponsive from "../TableResponsive";
import Title from "../Title";
const CustumerQuestionsList: FC = () => {
  return (
    <>
      <Title>Dúvidas clientes</Title>
      <TableResponsive columns={[]} data={[]} objectKey={[]} />
    </>
  );
};
export default CustumerQuestionsList;
