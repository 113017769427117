import axios from "axios";

const token = localStorage.getItem("token");

export default axios.create({
  baseURL: String(process.env.REACT_APP_URL_API),
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
  },
});

axios.defaults.withCredentials = true;
