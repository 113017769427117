import IconDashboard from "@mui/icons-material/Dashboard";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
const appMenuItems = [
  {
    name: "Dashboard",
    link: "/",
    Icon: IconDashboard,
    items: [],
  },
  {
    name: "Stores",
    Icon: StoreMallDirectoryIcon,
    items: [
      {
        name: "SOS Máscaras",
        items: [
          {
            name: "Orders",
            link: "/orders?store=13",
          },
          {
            name: "Customers",
            link: "/customers?store=13",
          },
        ],
      },
    ],
  },
  {
    name: "Catalog",
    Icon: LibraryBooks,
    items: [
      {
        name: "Products",
        link: "/products",
      },
      {
        name: "Categories",
        link: "/categories",
      },
      {
        name: "Attributes",
        items: [
          {
            name: "Attributes",
            link: "/attributes",
          },
          {
            name: "Attribute Groups",
            link: "/attributegroups",
          },
        ],
      },
    ],
  },
];
export default appMenuItems;
