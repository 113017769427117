import { FC } from "react";
import { Box, TextField, Button, Divider } from "@mui/material";
import TableResponsive from "../TableResponsive";
const ProductCostCenter: FC = () => {
  return (
    <Box
      sx={{
        mx: "auto",
        color: "#fff",
        p: 1,
        m: 1,
        textAlign: "center",
      }}
    >
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Expense Type"
          variant="outlined"
        />
        <TextField
          id="outlined-basic"
          label="Reference Code"
          variant="outlined"
        />
        <TextField id="outlined-basic" label="Description" variant="outlined" />
        <TextField id="outlined-basic" label="Observation" variant="outlined" />
        <TextField id="outlined-basic" label="Currency" variant="outlined" />
        <TextField id="outlined-basic" label="Price" variant="outlined" />
        <TextField id="outlined-basic" label="Date" variant="outlined" />
      </Box>
      <Button variant="contained" color="success" size="large">
        Success
      </Button>
      <Divider sx={{ mt: 1 }} />
      <TableResponsive
        columns={[
          "Expense Type",
          "Reference Code",
          "Description",
          "Observation",
          "Currency",
          "Price",
          "Date",
          "User",
        ]}
        data={[]}
        objectKey={[]}
      />
    </Box>
  );
};
export default ProductCostCenter;
