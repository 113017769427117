import { FC } from "react";
import { Box, TextField, Button, Divider } from "@mui/material";
import TableResponsive from "../TableResponsive";
const ProductSupplier: FC = () => {
  return (
    <Box
      sx={{
        mx: "auto",
        color: "#fff",
        p: 1,
        m: 1,
        textAlign: "center",
      }}
    >
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField id="outlined-basic" label="Supplier" variant="outlined" />
        <TextField id="outlined-basic" label="Quantity" variant="outlined" />
        <TextField id="outlined-basic" label="Currency" variant="outlined" />
        <TextField id="outlined-basic" label="Price" variant="outlined" />
        <TextField id="outlined-basic" label="Date" variant="outlined" />
      </Box>
      <Button variant="contained" color="success" size="large">
        Success
      </Button>
      <Divider sx={{ mt: 1 }} />
      <TableResponsive
        columns={["Supplier", "Quantity", "Currency", "Price", "Date"]}
        data={[]}
        objectKey={[]}
      />
    </Box>
  );
};
export default ProductSupplier;
