import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./ducks/auth";
import ordersReducer from "./ducks/orders";
import orderReducer from "./ducks/order";

const store = configureStore({
  reducer: {
    auth: authReducer,
    orders: ordersReducer,
    order: orderReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
