import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVerticalProps } from "../../types/tableResponsive";

const TableResponsiveVertical = (props: TableVerticalProps) => {
  return (
    <Paper className="container">
      <Table>
        <TableBody>
          {props.data.map((rows, index) => {
            return (
              <TableRow key={index}>
                {props.objectKey.map((keys) => {
                  return keys.map((key) => {
                    return (
                      <TableCell className={props.textAlign} key={key}>
                        {rows[key]}
                      </TableCell>
                    );
                  });
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};
export default TableResponsiveVertical;
