import { FC } from "react";
import Title from "../Title";
import TableResponsive from "../TableResponsive";
import {
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Paper,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import Swal from "sweetalert2";

type History = {
  date: string;
  comment: string;
  status: string;
  customer_notified: string;
};

type OrderHistoryProps = {
  order_id: string | null;
  store_id: string | null;
  isLoader: React.Dispatch<React.SetStateAction<boolean>>;
  history: History[];
};

const columns = ["Date Added", "Comment", "Status", "	Customer Notified"];
const keys = ["date", "comment", "status", "customer_notified"];
const orderStatus = [
  { label: "New", id: 1 },
  { label: "Canceled", id: 7 },
  { label: "Approved", id: 15 },
  { label: "Processing", id: 2 },
  { label: "Shipped", id: 3 },
  { label: "Delivered", id: 5 },
  { label: "Shipment Exception", id: 16 },
];

const OrderHistory: FC<OrderHistoryProps> = ({
  order_id,
  history,
  isLoader,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data: any) => {
    isLoader(true);
    await api
      .put("/order/status", {
        id: order_id,
        status: data.status,
        comment: data.comment,
        notify: data.notify,
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Order status updated",
          icon: "success",
          confirmButtonText: "OK",
        })
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            window.location.reload();
          });
        isLoader(false);
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Error updating order status",
          icon: "error",
          confirmButtonText: "OK",
        })
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            window.location.reload();
          });
        isLoader(false);
      });
  };
  return (
    <>
      <Title>Order History</Title>
      <TableResponsive columns={columns} data={history} objectKey={keys} />
      <Paper className="container">
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={orderStatus}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...register("status", { required: true })}
                {...params}
                label="Status"
              />
            )}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors.status && "Required"}
          </p>
          <TextField
            placeholder="Comment"
            label="Comment"
            fullWidth
            multiline
            sx={{ mt: 1 }}
            rows={4}
            {...register("comment", { required: true })}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors.comment && "Required"}
          </p>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={false}
                  {...register("notify", { required: false })}
                />
              }
              label="Notify Customer"
            />
          </FormGroup>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
            elevation={0}
          >
            <Fab type="submit">
              <AddIcon />
            </Fab>
          </Paper>
        </Box>
      </Paper>
    </>
  );
};
export default OrderHistory;
