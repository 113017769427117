import { Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import { LOGIN_PATH, DASHBOARD_PATH, paths } from "./config/router/paths";
import Login from "./components/views/Login";
import Dashboard from "./components/views/Dashboard";

import { useSelector, RootStateOrAny } from "react-redux";
import "./styles/global.css";
interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { isAuthenticated } = useSelector(
    (state: RootStateOrAny) => state.auth
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: LOGIN_PATH, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default function App() {
  const role = localStorage.getItem("role");
  const [routes, setRoutes] = useState([
    { path: DASHBOARD_PATH, component: Dashboard },
  ]);
  useEffect(() => {
    paths.forEach((path) => {
      if (path.role_id === Number(role) || Number(role) === 1) {
        setRoutes((prev) => [
          ...prev,
          { path: path.path, component: path.component },
        ]);
      }
    });
  }, [role]);
  return (
    <Suspense fallback="Cargando aplicación...">
      <Switch>
        <Route path={LOGIN_PATH} exact>
          <Login />
        </Route>
        {routes.map((path, i) => {
          return (
            <PrivateRoute
              key={i}
              path={path.path}
              component={path.component}
              exact
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
