import { FC } from "react";
import TableResponsive from "../TableResponsive";
import Title from "../Title";
import { ProductListProps } from "../../types/productList";
const ProductList: FC<ProductListProps> = ({
  title,
  columns,
  data,
  objectKey,
}) => {
  return (
    <>
      <Title>{title}</Title>
      <TableResponsive
        columns={columns}
        data={data}
        objectKey={objectKey}
        screen={"product"}
      />
    </>
  );
};
export default ProductList;
