import DefaultLayout from "../../layouts/DefaultLayout";
import {
  Box,
  TextField,
  Container,
  Grid,
  Paper,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
export default function Attributes() {
  return (
    <DefaultLayout>
      <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                width: "100%",
                typography: "body1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: "#fff",
              }}
            >
              <Box className="card__dashboard">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      padding: "12px 13px",
                      fontSize: "14px",
                      fontWeight: "normal",
                      lineHeight: 1,
                      color: "#555",
                      textAlign: "center",
                      backgroundColor: "#eee",
                      borderRadius: "3px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <img src="/assets/language/pt-br.png" alt="japan" />
                  </span>
                  <TextField size="small" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      padding: "12px 13px",
                      fontSize: "14px",
                      fontWeight: "normal",
                      lineHeight: 1,
                      color: "#555",
                      textAlign: "center",
                      backgroundColor: "#eee",
                      borderRadius: "3px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <img src="/assets/language/en-gb.png" alt="japan" />
                  </span>
                  <TextField size="small" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      padding: "12px 13px",
                      fontSize: "14px",
                      fontWeight: "normal",
                      lineHeight: 1,
                      color: "#555",
                      textAlign: "center",
                      backgroundColor: "#eee",
                      borderRadius: "3px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <img src="/assets/language/es-ES.png" alt="japan" />
                  </span>
                  <TextField size="small" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      padding: "12px 13px",
                      fontSize: "14px",
                      fontWeight: "normal",
                      lineHeight: 1,
                      color: "#555",
                      textAlign: "center",
                      backgroundColor: "#eee",
                      borderRadius: "3px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <img src="/assets/language/japan.png" alt="japan" />
                  </span>
                  <TextField size="small" />
                </div>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Select
                id="status"
                label="status"
                value=""
                sx={{
                  width: "50%",
                  m: 1,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="1">
                  <em>Enabled</em>
                </MenuItem>
                <MenuItem value="0">
                  <em>Disabled</em>
                </MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
