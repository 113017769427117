import { FC } from "react";
import { Button } from "@mui/material";
import { Receipt, LocalShipping, Description } from "@mui/icons-material";
import TableResponsiveVertical from "../TableResponsiveVertical";
import Title from "../Title";
import api from "../../services/api";
import Swal from "sweetalert2";
type OrderActionsProps = {
  order_id: string | null;
  isLoader: React.Dispatch<React.SetStateAction<boolean>>;
};
interface RequestInvoice {
  response: {
    code: number;
    error: string;
    message: string;
    key: string;
    url: string | URL | undefined;
  };
}
const OrderActions: FC<OrderActionsProps> = ({ order_id, isLoader }) => {
  const genetateInvoice = async () => {
    isLoader(true);
    await api
      .post<RequestInvoice>(`/invoice`, {
        order_id,
      })
      .then((res) => {
        isLoader(false);
        Swal.fire({
          title: "Success",
          text: res.data.response.message,
          icon: "success",
          confirmButtonText: "OK",
        });
        const w = window.open(res.data.response.url, "_blank");
        if (w) {
          w.focus(); // okay now
        }
      })
      .catch((err) => {
        isLoader(false);
        Swal.fire({
          title: "Error",
          text: err.data.response.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <>
      <Title>Actions</Title>
      <TableResponsiveVertical
        data={[
          {
            icon: <Description />,
            data: "Generate order summary",
          },
          {
            icon: <Receipt />,
            data: (
              <Button
                onClick={async () => {
                  await genetateInvoice();
                }}
              >
                Generate invoice
              </Button>
            ),
          },
          {
            icon: <LocalShipping />,
            data: <Button>Genarate label</Button>,
          },
        ]}
        objectKey={[["icon", "data"]]}
      />
    </>
  );
};
export default OrderActions;
