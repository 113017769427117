import { FC } from "react";
import { Box, TextField } from "@mui/material";

type Description = {
  name: string;
  description: string;
  meta_title: string;
  meta_description: string;
  meta_keyword: string;
  seo_keyword: string;
};

type Props = {
  description: Description[];
};

const ProductDescriptionForm: FC<Props> = ({ description }) => {
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TextField
        fullWidth
        label="Product Name"
        value={description[0].name}
        id="fullWidth"
        margin="normal"
      />
      <TextField
        label="Description"
        value={description[0].description}
        multiline
        fullWidth
        rows={7}
      />
      <TextField
        fullWidth
        value={description[0].meta_title}
        label="Meta Tag Title"
        id="fullWidth"
        margin="normal"
        disabled
      />
      <TextField
        label="Meta Tag Short Description"
        value={description[0].meta_description}
        multiline
        fullWidth
        rows={4}
      />
      <TextField
        fullWidth
        label="Meta Tag Keywords"
        value={description[0].meta_keyword}
        id="fullWidth"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Product Tags"
        value={description[0].seo_keyword}
        id="fullWidth"
        margin="normal"
        disabled
      />
    </Box>
  );
};
export default ProductDescriptionForm;
