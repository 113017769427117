import { FC } from "react";
import TableResponsive from "../TableResponsive";
import Title from "../Title";
const TaskList: FC = () => {
  return (
    <>
      <Title>Task</Title>
      <TableResponsive columns={[]} data={[]} objectKey={[]} />
    </>
  );
};
export default TaskList;
