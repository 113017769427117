import { useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import CategoryDescription from "../../CategoryDescription";
import CategoryData from "../../CategoryData";
import GlobalLoader from "../../GlobalLoader";
import { Container, Grid, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
export default function Categories() {
  const [value, setValue] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading && <GlobalLoader />}
      <DefaultLayout>
        <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Category Description" value="1" />
                      <Tab label="Category Data" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <CategoryDescription isLoader={setIsLoading} />
                  </TabPanel>
                  <TabPanel value="2">
                    <CategoryData />
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </DefaultLayout>
    </>
  );
}
