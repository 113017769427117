import { FC } from "react";
import TableResponsive from "../TableResponsive";
import Title from "../Title";

type Items = {
  id: number;
  customer: string;
  status: string;
  price: number;
  date: string;
  store: string;
};

interface OrderListPreviewProps {
  title: string;
  items: Items[];
}

const OrderListPreview: FC<OrderListPreviewProps> = ({ title, items }) => {
  return (
    <>
      <Title>{title}</Title>
      <TableResponsive columns={[]} data={[]} objectKey={[]} />
    </>
  );
};
export default OrderListPreview;
